'use strict'
import moment from 'moment'

const messager = (type, group, active, message, ...args) => {
  if (!active) return
  const typeColors = {
    log: '#555555',
    time: '#555555',
    info: '#123c42',
    success: '#2b3e11',
    warn: '#38310d',
    error: '#360b15'
  }
  const fontColors = {
    log: '#eeeeee',
    time: '#eeeeee',
    info: '#3bc6dc',
    success: '#97dc3b',
    warn: '#dcc13b',
    error: '#dc3b60'
  }

  const params = [
    '%c' + group + '%c' + message.toString(),
    'background:#1e1e1e ; padding: 2px 6px; border-radius: 3px 0 0 3px;  color: ' + fontColors[type] + '; font-style: italic',
    'background: ' + typeColors[type] + ' ; padding: 2px 6px; border-radius: 0 3px 3px 0;  color: ' + fontColors[type] + ';'
  ]

  if (type === 'error') {
    if (message instanceof Error) {
      args.splice(0, 0, message)
    }
    console.error(...params, ...args)
  } else if (type === 'warn') {
    console.warn(...params, ...args)
  } else {
    console.log(...params, ...args)
  }
}

const timers = {}

const loggerFactory = (group, active) => {
  const logger = (message, ...args) => messager('log', group, active, message, ...args)
  logger.info = (message, ...args) => messager('info', group, active, message, ...args)
  logger.success = (message, ...args) => messager('success', group, active, message, ...args)
  logger.log = (message, ...args) => messager('log', group, active, message, ...args)
  logger.warn = (message, ...args) => messager('warn', group, active, message, ...args)
  logger.error = (message, ...args) => messager('error', group, active, message, ...args)
  logger.time = (timer, message, ...args) => {
    timers[timer] = Date.now()
    if (message) {
      messager('time', group, active, message, ...args)
    }
  }
  logger.timeEnd = (timer, message, ...args) => {
    const diff = Date.now() - timers[timer]
    if (diff > 120000) {
      args.push('time: ' + moment(timers[timer]).fromNow(true))
    } else {
      const s = Math.floor(diff / 1000)
      const ms = diff - s
      args.push('time: ' + s + 's ' + ms + 'ms')
    }
    messager('time', group, active, message, ...args)
  }
  return logger
}

const testError = new Error('Logger Test Error')
const logger = window.logger = loggerFactory('Logger', false)
logger.log('Testing logger.log')
logger.info('Testing logger.info')
logger.success('Testing success.info')
logger.warn('Testing logger.warn')
logger.error(testError, 'Testing logger.error')
logger.time('Testing logger.time')
logger.timeEnd('Testing logger.time', 'Testing message')

export default loggerFactory
